@font-face {
  font-family: 'Suisse';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('/fonts/SuisseIntl-Regular-WebS.woff2');
}
@font-face {
  font-family: 'Suisse';
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: url('/fonts/SuisseIntl-RegularItalic-WebS.woff2');
}
@font-face {
  font-family: 'Suisse';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('/fonts/SuisseIntl-Bold-WebS.woff2');
}
@font-face {
  font-family: 'Suisse';
  font-style: italic;
  font-weight: bold;
  font-display: swap;
  src: url('/fonts/SuisseIntl-BoldItalic-WebS.woff2');
}
@font-face {
  font-family: 'Suisse';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('/fonts/SuisseIntl-Thin-WebS.woff2');
}
@font-face {
  font-family: 'Suisse';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url('/fonts/SuisseIntl-ThinItalic-WebS.woff2');
}
@font-face {
  font-family: 'Suisse';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/fonts/SuisseIntl-Light-WebS.woff2');
}
@font-face {
  font-family: 'Suisse';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('/fonts/SuisseIntl-LightItalic-WebS.woff2');
}
@font-face {
  font-family: 'Suisse';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/SuisseIntl-Book-WebS.woff2');
}
@font-face {
  font-family: 'Suisse';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/SuisseIntl-BookItalic-WebS.woff2');
}
@font-face {
  font-family: 'Suisse';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/fonts/SuisseIntl-Medium-WebS.woff2');
}
@font-face {
  font-family: 'Suisse';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('/fonts/SuisseIntl-MediumItalic-WebS.woff2');
}
@font-face {
  font-family: 'Suisse';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/SuisseIntl-SemiBold-WebS.woff2');
}
@font-face {
  font-family: 'Suisse';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/SuisseIntl-SemiBoldItalic-WebS.woff2');
}
