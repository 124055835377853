@tailwind base;
@tailwind components;

@import './fonts.css';

:root {
  --eql-header-height: 4rem;
  --eql-section-toolbar-height: 73px;

  --eql-color-primary: theme('colors.primary.DEFAULT');
  --eql-color-primary-hover: theme('colors.primary.hover');
  --eql-color-primary-background: theme('colors.primary.background');

  --eql-color-secondary: theme('colors.secondary.DEFAULT');
  --eql-color-secondary-hover: theme('colors.secondary.hover');
  --eql-color-secondary-background: theme('colors.secondary.background');

  --eql-color-error: theme('colors.error.DEFAULT');
  --eql-color-error-hover: theme('colors.error.hover');
  --eql-color-error-background: theme('colors.error.background');

  --eql-color-warning: theme('colors.warning.DEFAULT');
  --eql-color-warning-hover: theme('colors.warning.hover');
  --eql-color-warning-background: theme('colors.warning.background');

  --eql-color-success: theme('colors.success.DEFAULT');
  --eql-color-success-hover: theme('colors.success.hover');
  --eql-color-success-background: theme('colors.success.background');

  --eql-color-link: theme('colors.link.DEFAULT');
  --eql-color-link-hover: theme('colors.link.hover');
  --eql-color-link-background: theme('colors.link.background');

  --eql-color-gray: theme('colors.gray.DEFAULT');

  --eql-color-map-marker: theme('colors.map.marker.DEFAULT');
  --eql-color-map-marker-active: theme('colors.map.marker.active');
  --eql-color-map-marker-place: theme('colors.map.marker.place');
  --eql-color-map-marker-cluster: theme('colors.map.marker.cluster');

  --eql-color-infobric: theme('colors.infobric.DEFAULT');
  --eql-color-infobric-hover: theme('colors.infobric.hover');
  --eql-color-infobric-background: theme('colors.infobric.background');
}

html,
body,
#__next {
  @apply h-full w-full;
  background-color: #fafafa;
}

::selection {
  @apply bg-primary text-white;
}

button,
input {
  @apply focus:outline-none;
}

@media print {
  #print {
    display: block;
  }

  body > div:not(#print) {
    display: none;
  }
}

@media screen {
  #print {
    display: none;
  }
}

input[type='search']::-webkit-search-cancel-button {
  display: none;
}

#embed > * {
  user-select: none;
}

span[data-slate-placeholder='true'] {
  top: 0;
}

#amount-input::-webkit-outer-spin-button,
#amount-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#amount-input {
  -moz-appearance: textfield;
}

@tailwind utilities;
